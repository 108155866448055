import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// components
import BlogFooter from "../components/blog/BlogFooter"

// importing images
import hostparty from "../images/blog/host.png"
import invite from "../images/blog/create.png"
import share from "../images/blog/share.png"

const Legal = () => {
  return (
    <PageWrapper>
      <Wrapper>
        <BlogHeader>#QueueFriday Official Rules</BlogHeader>
        <Section>
          <Paragraph>
            NO PURCHASE NECESSARY TO ENTER OR WIN A PRIZE IN THIS GIVEAWAY
            (DEFINED BELOW). A PURCHASE WILL NOT INCREASE YOUR CHANCES OF
            WINNING. VOID WHERE PROHIBITED BY LAW ENTRY VIA INSTAGRAM OR
            FACEBOOK ONLY AND AN INSTAGRAM OR FACEBOOK ACCOUNT AND INTERNET
            CONNECTION ARE REQUIRED. A “PROTECTED” INSTAGRAM ACCOUNT MAY NOT BE
            ABLE TO SEND ENTRIES. THE GIVEAWAY IS IN NO WAY SPONSORED, ENDORSED
            OR ADMINISTERED BY FACEBOOK, INC. OR SNAPCHAT, INC (where
            advertisements will occur).
            <br />
            <br />
            BY ENTERING THE GIVEAWAY YOU AGREE TO THESE OFFICIAL RULES, WHICH
            ARE A CONTRACT, SO READ THEM CAREFULLY BEFORE ENTERING. WITHOUT
            LIMITATION, THIS CONTRACT INCLUDES INDEMNITIES TO THE SPONSOR FROM
            YOU AND A LIMITATION OF YOUR RIGHTS AND REMEDIES. GENERAL
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>General:</p>
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>1. Sponsor:</p>
            Queue Friday (“Giveaway”) is sponsored by Queue ApS (“Sponsor” or
            “Queue”).
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>2. Eligibility:</p>
            The Giveaway is open only to legal residents of Denmark. Employees,
            officers, directors, members, managers, agents, and representatives
            and family members of such individuals (or people living in the same
            household whether related or not) of Sponsor, or their corporate
            partners, parent companies, divisions, subsidiaries, affiliates,
            successors in interest, advertising, promotion, and public relations
            agencies (collectively, the “Giveaway Entities”) are not eligible.
            For the purposes of the Giveaway, family members are defined as
            spouse, partner, mother, father, legal guardian, in-laws,
            grandmother, grandfather, brother, sister, children and
            grandchildren (including “step” as they may apply).
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>3. Giveaway Period:</p>
            The “Giveaway Period” begins at 11:45 AM GMT+2 on October 23, 2020
            and ends at 11:45 AM GMT+2 on October 24, 2020. Prize Winners will
            be selected just after the Giveaway Period.
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>4. How to Enter:</p>
            To participate and enter this Giveaway, you will need an Instagram
            or Facebook account (either a “Social Media Account”). If you don’t
            already have an Instagram account, visit www.Instagram.com to create
            one; creating an Instagram account is free. By submitting your
            information and creating an Instagram account, you will be required
            to agree to the Instagram terms of service and privacy notice. If
            you do not agree to Instagram’s terms of service and privacy notice,
            you cannot create an Instagram account. If you don’t already have a
            Facebook account, visit www.Facebook.com to create one; creating a
            Facebook account is free. By submitting your information and
            creating a Facebook account, you will be required to agree to the
            Facebook terms of service and privacy notice. If you do not agree to
            Facebook’s terms of service and privacy notice, you cannot create a
            Facebook account. <br />
            To enter the Giveaway, eligible individuals (“Entrants”) must follow
            the official Queue App account on Instagram and either: (1) leave a
            comment on Queue App’s Instagram page, specifically on the post
            promoting this Giveaway that tags a friend; or (2) comment “done” on
            a official Queue Facebook post which can be found in different
            Facebook groups. <br /> Sponsor may disqualify entries if the Entry
            and related tag includes inappropriate, offensive, or other
            derogatory language or information. Sponsor reserves the right to
            remove, reject, or disqualify (along with the entrants who submitted
            them) any Entry or related tag which, in its opinion, (a) violates
            any of these Official Rules, the terms of service and privacy policy
            of Instagram, or Queue’s, (b) infringes, misappropriates, or
            violates any rights of any third party including, without
            limitation, patent, copyright, trademark, trade secret, or right of
            privacy or publicity, or (c) is otherwise inappropriate for
            inclusion in the Giveaway. For purposes of this Giveaway, only
            Entries that are recorded through Instagram’s or Facebook’s servers
            will be considered. Other proof of submitting an Entry (such as a
            printed or copied screenshot or message) does not constitute proof
            of actual receipt of the Entry for purposes of this Giveaway. The
            Facebook or Instagram database clock will be the official timekeeper
            for this Giveaway. NOTE: Sponsor may not receive entries from
            Facebook or Instagram users with “protected” or “private” updates
            (i.e., user has set their Social Media Account or so that only
            people the user has approved can view updates) due to the way
            Facebook or Instagram operates its service. Facebook or Instagram
            posts not received by Sponsor will not be entered into the Giveaway.
            Multiple entrants are not permitted to tag the same Social Media
            Account. Attempts made by an individual to submit Entries in
            violation of these Official Rules by using multiple or false contact
            information or otherwise may be disqualified. Entries that are
            generated by a macro, bot, or other automated means will not be
            accepted and will be void. Entries made by any other individual or
            any entity or group, or originating at any website other than as set
            forth specifically above, including, without limitation, through
            commercial Giveaway subscription notification or entering services,
            will be declared invalid and disqualified for this Giveaway. As a
            condition of entering the Giveaway, without limiting any other
            provision in these Official Rules, each entrant gives consent for
            Sponsor and its agents to obtain and deliver his or her name,
            address and other information to third parties for the purpose of
            administering this Giveaway and complying with applicable laws,
            regulations, and rules. Such third parties may use your information
            for their own independent purposes in accordance with their own
            independent privacy practices.
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>NOTE ABOUT INSTAGRAM:</p>
            Giveaway Entities are not responsible for any changes or
            unavailability of the Facebook or Instagram service that may
            interfere with the Giveaway (including any limitations, any
            restrictions, or any conditions on Sponsor’s ability to use Facebook
            or Instagram for the Giveaway as set forth herein that are not
            acceptable to Sponsor) or ability of entrant to timely enter,
            receive notices or communicate with Sponsor via Facebook or
            Instagram, in which case Sponsor, in its sole discretion, may
            terminate or modify the Giveaway. It is a potential winner’s
            responsibility to set his/her Social Media Account as applicable, to
            accept contacts by Sponsor and to timely check such account for any
            Sponsor direct messages.
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>5. Winners Selection:</p>
            Prize Winners will be chosen at random. Your odds of winning depend
            on the number of eligible Entries received.
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>6. Winner Notification:</p>
            The Prize Winners will be notified by receiving a direct message
            from Queue to their Facebook or Instagram account with a request for
            their phone number (a “Winner Notification”). Once the Prize Winner
            provides such information they will receive the cash prize on their
            bank account. <br /> If any Winner Notification or other Giveaway
            communication is rejected or returned as undeliverable, if a
            potential Winner cannot be reached after a reasonable attempt has
            been made by Sponsor (as determined by Sponsor in its sole
            discretion), if a Winner cannot attend or participate in any portion
            of the applicable Prize, or if the potential Winner fails to respond
            to a Winner Notification and/or to sign and return the Affidavit (if
            required) within the time periods set forth herein, the prize will
            be forfeited in Sponsor’s sole discretion and an alternate winner
            may be selected (time permitting) based on the original method of
            winner selection in Sponsor’s sole discretion. Sponsor may only look
            to five (5) alternate winners for the applicable Prize, at which
            point the applicable Prize may be deemed unclaimed and Sponsor will
            have no further obligation with respect to such Prize. Sponsor
            reserves the right to modify the notification procedures in
            connection with the selection of any alternate potential Winner. If
            legitimately claimed in compliance with these Official Rules, the
            Prize will be awarded. To claim a Prize, a Winner must follow the
            directions in his or her Winner Notification.
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>7. Prize:</p>
            One person will win a prize of 1000 DKK. The Prize Winners receive
            their prize as a deposit into their bank account through MobilePay.
            <br />
            All Prize details not specified in these Official Rules will be
            determined in Sponsor’s sole discretion. Sponsor is not responsible
            for and will not replace any lost or stolen Prize or any Prize that
            is undeliverable or does not reach the Prize Winner because of
            incorrect or changed contact information. If the Prize Winner does
            not accept or use the entire Prize, the unaccepted or unused part of
            the Prize will be forfeited and Sponsor will have no further
            obligation with respect to that Prize or portion of the Prize.
            Sponsor is not responsible for any inability of the Prize Winner to
            accept or use any Prize (or portion thereof) for any reason. No
            transfers or prize substitutions will be made, except at Sponsor’s
            sole discretion. No more than the stated prizes will be awarded.
            Participants waive the right to assert as a cost of winning any
            Prize, any and all costs of verification and redemption or travel to
            claim or use a Prize and any liability and publicity which might
            arise from claiming, seeking to claim, or using a Prize.
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>8. Licenses and Approvals:</p>
            Entrants may not submit an Entry on behalf of someone else. By
            submitting an Entry, Entrant represents and warrants that Entrant
            meets all eligibility criteria for participation in the Giveaway. In
            addition, by submitting an Entry in this Giveaway, Entrant grants
            the Giveaway Entities an irrevocable, perpetual, non-exclusive
            worldwide license to publish, post, display, copy, distribute,
            transmit, edit, use alone, together or with other works, and
            otherwise exploit the Entry, Entrants name, voice, persona,
            likeness, image, prize information, any quotes attributable to
            Entrant, biographical data and any other indicia of persona in any
            manner whatsoever throughout the world, including on the Internet,
            and at any time or times, in connection with the Giveaway, in any
            and all forms of media, now known or hereafter discovered, without
            additional compensation, review or approval rights, notification or
            permission, except where prohibited by law, and Entrant releases all
            Releasees from any and all liability related thereto. ENTRANT
            FURTHER ACKNOWLEDGES THAT IF ENTRANT IS CHOSEN AS A WINNER, ENTRANTS
            IDENTIFYING INFORMATION, INCLUDING BUT NOT LIMITED TO ENTRANTS NAME,
            MAY BE DISCLOSED TO THIRD PARTIES INCLUDING, WITHOUT LIMITATION,
            BEING PLACED ON A WINNERS’ LIST.
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>9. Limitation of Liability:</p>
            The Releasees (as defined below) are not responsible or liable for
            any of the following: (a) stolen, lost, late, misdirected, garbled,
            mutilated, damaged, incomplete, inaccurate, or illegible Entries,
            e-mail, mail, Giveaway-related correspondence or postage-due mail or
            any technical, computer, mechanical, printing, typographical, human
            or other errors relating to or in connection with the Giveaway,
            including, without limitation, errors which may occur in connection
            with the administration of the Giveaway, the mailing or transmission
            of notification or correspondence, the processing of Entries, the
            announcement of any prize or in any Giveaway related materials; (b)
            technical failures of any kind; (c) unauthorized human and/or
            mechanical intervention; (d) human error; (e) any error, omission,
            interruption, defect or delay in transmission or communication; (f)
            viruses or mechanical malfunctions; (g) errors, typos, or misprints
            in these Official Rules, in any Giveaway related advertisements or
            other materials; or (h) lost or unavailable network connections.
            Sponsor is not responsible for electronic communications that are
            undeliverable as a result of any form of filtering or insufficient
            space in Entrant’s e-mail account. Sponsor is not responsible, and
            may disqualify Entrant, if his or her e-mail address, telephone, or
            other contact information does not work or if it is changed without
            Entrant giving prior written notice to Sponsor. If for any reason an
            Entry is confirmed to have been erroneously deleted, lost or
            otherwise destroyed, corrupted or for any other reason not accepted
            as an entry into the Giveaway, Entrant’s sole remedy is another
            Entry in the Giveaway.
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>11. Disputes and Governing Law:</p>
            BY PARTICIPATING IN THE GIVEAWAY, EACH ENTRANT AGREES THAT TO THE
            EXTENT PERMITTED BY APPLICABLE LAW: (1) ANY AND ALL DISPUTES, CLAIMS
            AND CAUSES OF ACTION THAT CANNOT BE RESOLVED BETWEEN ENTRANT AND ANY
            RELEASEE ARISING OUT OF OR CONNECTED WITH THE GIVEAWAY, OR ANY PRIZE
            AWARDED, WILL BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM
            OF CLASS ACTION; (2) ANY AND ALL CLAIMS, JUDGMENTS AND AWARDS WILL
            BE LIMITED TO ACTUAL THIRD-PARTY, OUT-OF-POCKET COSTS INCURRED, (IF
            ANY), NOT TO EXCEED 100 DKK, BUT IN NO EVENT WILL ATTORNEYS’ FEES BE
            AWARDED OR RECOVERABLE; (3) UNDER NO CIRCUMSTANCES WILL ANY ENTRANT
            BE PERMITTED TO OBTAIN ANY AWARD FOR, AND ENTRANT HEREBY KNOWINGLY
            AND EXPRESSLY WAIVES ALL RIGHTS TO SEEK, PUNITIVE, INCIDENTAL,
            CONSEQUENTIAL OR SPECIAL DAMAGES, LOST PROFITS AND/OR ANY OTHER
            DAMAGES, OTHER THAN ACTUAL OUT-OF-POCKET EXPENSES NOT TO EXCEED 100
            DKK, AND/OR ANY RIGHTS TO HAVE DAMAGES MULTIPLIED OR OTHERWISE
            INCREASED; AND (4) ENTRANT’S REMEDIES ARE LIMITED TO A CLAIM FOR
            MONEY DAMAGES (IF ANY) AND ENTRANT IRREVOCABLY WAIVES ANY RIGHT TO
            SEEK INJUNCTIVE OR EQUITABLE RELIEF. <br />
            Any dispute arising under or related hereto (whether for breach of
            contract, tortious conduct or otherwise) will be governed by the
            laws of Denmark, without reference to its conflicts of law
            principles and will be brought exclusively in the courts located in
            Denmark and each entrant accepts and submits to the personal
            jurisdiction of these Danish courts with respect to any legal
            actions, suits or proceedings arising from or relating to this
            Giveaway or these Official Rules.
          </Paragraph>
          <Paragraph>
            <p style={{ fontWeight: 600 }}>12. General Rules: </p>
            Sponsor’s failure to enforce any term of these Official Rules shall
            not constitute a waiver of that provision. Sponsor’s decisions will
            be final in all matters relating to the Giveaway, Sponsor reserves
            the right to restrict or void online Entries or participation from
            any IP address if any suspicious Entry and/or participation is
            detected. Sponsor reserves the right, in its sole discretion, to
            void Entries of any Entrants who Sponsor believes has attempted to
            tamper with or impair the administration, security, fairness or
            proper play of the Giveaway. In the event there is an alleged or
            actual ambiguity, discrepancy or inconsistency between disclosures
            or other statements contained in any Giveaway-related materials
            and/or these Official Rules (including any alleged discrepancy or
            inconsistency in these Official Rules), it will be resolved in
            Sponsor’s sole discretion. Entrants waive any right to claim
            ambiguity in the Giveaway or these Official Rules. If Sponsor
            determines at any time in its sole discretion that Entrant is
            disqualified, ineligible, or in violation of these Official Rules,
            or engaging in behavior that Sponsor deems obnoxious, inappropriate,
            threatening, illegal or that is intended to annoy, abuse, threaten
            or harass any other person, Sponsor reserves the right in its sole
            discretion to select an alternate winner. The invalidity or
            unenforceability of any provision of these Official Rules will not
            affect the validity or enforceability of any other provision. If the
            Giveaway is not capable of running as planned for any reason,
            Sponsor reserves the right, in its sole discretion, to cancel,
            modify or suspend the Giveaway and award the prize from eligible
            entries received prior to cancellation, modification, or suspension
            or as otherwise deemed fair and appropriate by Sponsor. In the event
            of a dispute concerning who submitted an Entry, the participant will
            be declared to be the registered account holder of the Social Media
            Account as applicable, of record for the account submitting an Entry
            during the Giveaway Period, but only if that person meets all other
            eligibility criteria, otherwise the Entry may be disqualified and
            any potential Prize won will be forfeited in Sponsor’s sole
            discretion. Each potential Winner may be required to provide Sponsor
            with proof that he or she is the authorized account holder. If a
            dispute cannot be resolved to Sponsor’s satisfaction, the Entry and
            individual(s) may be deemed ineligible in Sponsor’s sole discretion
            Any damage made to the Website, the Instagram service will be the
            responsibility of the registered account holder of the e-mail
            address for the Social Media Account, as applicable, submitted at
            the time of entry. CAUTION: ANY ATTEMPT TO DAMAGE ANY ONLINE SERVICE
            OR WEB SITE OR UNDERMINE THE LEGITIMATE OPERATIONS OF THE GIVEAWAY
            VIOLATES CRIMINAL AND CIVIL LAWS. IF SUCH AN ATTEMPT IS MADE,
            SPONSOR MAY SEEK DAMAGES TO THE FULLEST EXTENT PERMITTED BY LAW AND
            MAY DISQUALIFY ANY PARTICIPANT MAKING SUCH ATTEMPT.
          </Paragraph>
        </Section>
      </Wrapper>
    </PageWrapper>
  )
}

export default Legal

// Page Wrapper
const PageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`

// Content wrapper
const Wrapper = styled.div`
  max-width: 700px;
  height: auto;

  background-color: inherit;

  margin: 2.5rem 2.5rem 0 2.5rem;
`

// Blog header and subheader
const BlogHeader = styled.h1`
  font-family: "Avenir";
  font-size: 2rem;
  font-weight: 600;

  line-height: 1.3;

  margin-bottom: 0.5rem;

  @media (min-width: 500px) {
    font-size: 2.5rem;
  }

  @media (min-width: 650px) {
    font-size: 2.8rem;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

const Teaser = styled.p`
  font-family: "Avenir";
  font-size: 1.3rem;
  font-style: italic;

  margin-bottom: 1.7rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
  @media (min-width: 750px) {
    font-size: 1.8rem;
  }
`

// -------------

// Section styles
const Section = styled.section`
  max-width: inherit;
  min-height: 150px;

  height: auto;

  margin-bottom: 1.5rem;

  display: flex;
  flex-direction: column;
`

// Sticking header and bar together
const HeaderContainer = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  margin-bottom: 1rem;
`

const Header = styled.h1`
  font-family: "Avenir";
  font-size: 1.7rem;
  font-weight: 700;

  line-height: 1;

  margin-bottom: 0.6rem;

  @media (min-width: 500px) {
    font-size: 2rem;
  }

  @media (min-width: 650px) {
    font-size: 2.2rem;
  }
`

const YellowBar = styled.div`
  width: 80px;
  height: 15px;

  background-color: #fdde68;

  @media (min-width: 500px) {
    width: 100px;
  }

  @media (min-width: 650px) {
    width: 115px;
  }
`

const Paragraph = styled.p`
  font-family: "Avenir";
  font-size: 1.3rem;
  font-style: normal;

  line-height: 1.3;

  margin-bottom: 1rem;

  @media (min-width: 500px) {
    font-size: 1.3rem;
  }

  @media (min-width: 650px) {
    font-size: 1.4rem;

    margin-bottom: 2rem;
  }

  @media (min-width: 750px) {
    font-size: 1.5rem;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
`

const Image = styled.div`
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 500px) {
    width: 280px;
    height: 280px;
  }

  @media (min-width: 650px) {
    width: 320px;
    height: 320px;
  }

  @media (min-width: 750px) {
    width: 370px;
    height: 370px;
  }
`

// -------------

// Images extended from Image
const Image1 = styled(Image)`
  background-image: url(${hostparty});
`

const Image2 = styled(Image)`
  background-image: url(${invite});
`

const Image3 = styled(Image)`
  background-image: url(${share});
`

// Link to next guide
const More = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
`

const BoldParagraph = styled.p`
  font-family: "Avenir";
  font-size: 1.2rem;

  line-height: 1.3;

  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
`

const Button = styled.div`
  width: 150px;
  height: 40px;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 500px) {
    width: 200px;
    height: 45px;
  }

  @media (min-width: 650px) {
    width: 250px;
    height: 50px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-family: "Avenir";
  font-size: 1.2rem;
  text-decoration: none;

  color: black;

  @media (min-width: 650px) {
    font-size: 1.4rem;
  }
`
